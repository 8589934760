// mega-menu offsetting submenus
var id;
$(window).resize(function() {
    clearTimeout(id);
    id = setTimeout(megaMenu, 500);
});
function megaMenu(){
	var menuPadding = 20;
	$('li.mega-dropdown').each(function (i) {
		var count = (i+1);
		var menu = $("li.mega-dropdown:nth-child(" + count + ")");
		var position = menu.position();
		var menuwidth = menu.width();
		var width = $(".dropdown li:nth-child(" + count + ") ul").width();
		var marginleft = position.left - menuPadding + menuwidth/2 - width/2 + "px";
		$(".dropdown li:nth-child(" + count + ") ul").css("margin-left",marginleft);
	});
}

	
$(document).ready(function() {

// mega-menu offsetting submenus
	megaMenu();

// backtotop
	$('.backtotop').click(function () {
	$("html, body").animate({
	scrollTop: 0
	}, 600);
	return false;
	});

	// hide off-canvas menu
	$('.off-canvas .nested a').on('click', function() {
	$('.off-canvas').foundation('close');
	});

});

//ie fix
$(document).on("opened.zf.offcanvas", function(e) {
$(".title-bar").css("position", "relative");
$(".top-space-mobile").css("display", "none");

});
$(document).on("closed.zf.offcanvas", function(e) {
$(".title-bar").css("position", "fixed");
$(".top-space-mobile").css("display", "block");
});

function validateForm() {
  	var valid = 1;
	var filter = /^[a-zA-Z0-9.!#$%&'*+/=?^_'`'{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    var radio = document.getElementsByClassName("radio");
    var ftitle = document.forms["contactform"]["ftitle"];
    var femail = document.forms["contactform"]["femail"];
    var fprename = document.forms["contactform"]["fprename"];
    var flastname = document.forms["contactform"]["flastname"];
    var fsubject = document.forms["contactform"]["fsubject"];
    var fmessage = document.forms["contactform"]["fmessage"];
	//email    
    if (femail.value == null || femail.value == "") {
        $(femail).addClass('error');
        valid = 0;
    }
    if(!filter.test(femail.value)) {
        $(femail).addClass('error');
        valid = 0;
    }
	//other    
    if (ftitle.value == null || ftitle.value == "") {
        $(radio).addClass('error');
        valid = 0;
    }
    if (fprename.value == null || fprename.value == "") {
        $(fprename).addClass('error');
        valid = 0;
    }
    if (flastname.value == null || flastname.value == "") {
        $(flastname).addClass('error');
        valid = 0;
    }
    if (fsubject.value == null || fsubject.value == "") {
        $(fsubject).addClass('error');
        valid = 0;
    }
    if (fmessage.value == null || fmessage.value == "") {
        $(fmessage).addClass('error');
        valid = 0;
    }
    if (!valid){
    return false;
    }
}

$(document).foundation();
